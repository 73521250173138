export const customerPortalApi = async <ReturnDataType>({
  url,
  options,
  accessToken,
  transactionId,
  type = 'json',
}: {
  url: string | URL;
  options?: RequestInit;
  accessToken: Promise<string>;
  transactionId: string;
  type?: 'json' | 'text';
}): Promise<ReturnDataType> => {
  const fetchOptions = options ?? {};

  const headers = new Headers(fetchOptions.headers);

  headers.set('Authorization', `Bearer ${await accessToken}`);
  headers.set('X-Transaction-ID', transactionId);
  headers.set('Content-Type', 'application/json');
  fetchOptions.headers = headers;

  const response = await fetch(url, fetchOptions).then((response) => {
    if (response.status === 200) {
      return response;
    }

    throw Error(response.statusText);
  });

  return response[type]() as ReturnDataType;
};
