import { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';
import { csrdRoutePaths } from 'core/lib/routePaths/csrdRoutePaths';
import { RouteLoader } from '#components/Loader/RouteLoader';

const CSRDHome = lazy(() => import('csrd/central-routes/home-routes'));

const About = lazy(() =>
  import('csrd/central-routes/home-routes').then((module) => ({
    default: module.AboutRoute,
  })),
);

const MyReports = lazy(() =>
  import('csrd/central-routes/home-routes').then((module) => ({
    default: module.MyReportsRoute,
  })),
);

const Checkout = lazy(() =>
  import('csrd/central-routes/home-routes').then((module) => ({
    default: module.CheckoutRoute,
  })),
);

export const CSRDRouteTree = [
  <Route
    key={0}
    path={csrdRoutePaths.HOME}
    index
    element={
      <Suspense fallback={<RouteLoader />}>
        <CSRDHome />
      </Suspense>
    }
  />,
  <Route
    key={1}
    path={csrdRoutePaths.ABOUT}
    element={
      <Suspense fallback={<RouteLoader />}>
        <About />
      </Suspense>
    }
  />,
  <Route
    key={2}
    path={csrdRoutePaths.MY_REPORTS}
    element={
      <Suspense fallback={<RouteLoader />}>
        <MyReports />
      </Suspense>
    }
  />,
  <Route
    key={2}
    path={csrdRoutePaths.CHECKOUT}
    element={
      <Suspense fallback={<RouteLoader />}>
        <Checkout />
      </Suspense>
    }
  />,
];

export const preloadCSRD = () => {
  //Import default export to trigger preload
  void import('csrd/central-routes/home-routes');
};
